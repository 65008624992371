rodijco.controller 'CertificatesFormController', [
  '$scope'
  '$routeParams'
  '$location'
  'httpService'
  (scope, routeParams, location, httpService) ->

    scope.hide = 1
    scope.saveMessage = ''
    scope.formData = {
      certificate: {
        fields: {
          0: {}
          1: {}
          2: {}
          3: {}
          4: {}
          5: {}
        }
        fields_middle: {
          0: {}
          1: {}
        }
        fields_bottom: {
          0: {}
          1: {}
          2: {}
          3: {}
          4: {}
        }
      }
    }
    scope.group = routeParams.group
    scope.id = routeParams.id
    scope.edit = routeParams.id != 'new' ? true : false
    scope.old_cer = false

    scope.werklastOptions = ['1.05', '1.1', '1.25', '1.5', '2.0', 'K1', 'K2', 'K3']

    if scope.group
      httpService.get "certificates/groups/#{scope.group}", (response) ->
        scope.formData.certificate_group = response.data

        httpService.get 'config', (response) ->
          scope.config = response.data[0]
          scope.hide = 0

        if scope.edit
          httpService.get "certificates/#{scope.id}", (response) ->
            scope.formData = response.data

            if scope.formData.old_cer == true
              scope.old_cer = true

    scope.save = (formData, deleteOld = false) ->
      formData._id = scope.id
      formData.deleteOld = true if deleteOld
      scope.hide = 1
      scope.saveMessage = 'Bezig met opslaan...'

      httpService.post "certificates/#{routeParams.id}", formData, (response) ->
        if response.success
          scope.formData = {}
          if scope.edit
            Materialize.toast 'Duplicaat opgeslagen', DIALOG_DURATION
            if formData.deleteOld
              Materialize.toast 'Oud certificaat verwijderd', DIALOG_DURATION
          else
            Materialize.toast 'Certificaat opgeslagen', DIALOG_DURATION

          location.path("/certificates/#{scope.group}")
        else
          scope.message = response.data?.message

    scope.getCertificateNumber = (field_name, index) ->
      setTimeout ->
        httpService.get 'certificates/count', (response) ->
          return unless response.success

          scope.formData.certificate[field_name][index] = {}
          scope.formData.certificate[field_name][index].name = parseInt(response.data.count, 10) + 1

      , 100

    scope.getDate = (field_name, index) ->
      today = new Date()
      dd = today.getDate()
      mm = today.getMonth() + 1
      yyyy = today.getFullYear()

      if dd < 10
        dd='0'+dd

      if mm < 10
        mm='0'+mm

      today = dd+'-'+mm+'-'+yyyy
      scope.formData?.certificate?[field_name]?[index]?.name = today

    scope.$watch 'formData.product', ->
      return unless scope.formData?.product?.originalObject?.product_group?
      return unless typeof scope.formData?.product?.originalObject?.product_group == 'string'

      httpService.get "products/groups/#{scope.formData.product.originalObject.product_group}", (response) ->
        if response.success
          scope.formData.product.originalObject.product_group = response

]
